import * as React from "react"
import "./style.scss"

const Propaganda = ({}) => {
  return (
    <div
      css={{
        backgroundColor: "#efeff3",
      }}
    >
      <div className="container">
        <div className="propaganda row justify-content-between align-center">
          <div className="col-6 col-md-3 rcol">
            <div className="item">
              <b>10年</b>
              <p>核心团队拥有超过10年行业经验</p>
              <span className="solid-right d-none d-md-block"></span>
            </div>
          </div>
          <div className="col-6 col-md-3 rcol">
            <div className="item">
              <b>原创</b>
              <p>拥有自主软件著作权，坚持项目 定制开发</p>
              <span className="solid-right d-none d-md-block"></span>
            </div>
          </div>
          <div className="col-6 col-md-3 rcol">
            <div className="item">
              <b>100项</b>
              <p>100多种系统开发细节标准</p>
              <span className="solid-right d-none d-md-block"></span>
            </div>
          </div>
          <div className="col-6 col-md-3 rcol">
            <div className="item">
              <b>7 * 24小时</b>
              <p>我们提供7 * 24小时优质服务， 技术在线答疑</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Propaganda
