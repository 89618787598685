import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Bounce } from "react-reveal"
import "./style.scss"

const Superiority = ({}) => {
  const query = useStaticQuery(graphql`
    query {
      icon51: file(relativePath: { eq: "i-量身定制.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon52: file(relativePath: { eq: "i-开发规范.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon53: file(relativePath: { eq: "i-设计人性化.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon54: file(relativePath: { eq: "i-安全稳定.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon55: file(relativePath: { eq: "i-完整交付.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon56: file(relativePath: { eq: "i-完善售后.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)

  const data = [
    {
      title: "量身定制",
      describe:
        "您的想法加上我们的专业技术及服务能力补充您想得到的产品，绝非生搬硬套式开发。",
      img: query.icon51.childImageSharp.gatsbyImageData,
    },
    {
      title: "开发规范",
      describe:
        "采用GIT等工具进行代码版本控制可以追溯源代码历史，技术文档详细规范。",
      img: query.icon52.childImageSharp.gatsbyImageData,
    },
    {
      title: "设计人性化",
      describe:
        "资深设计团队，设计师深耕互联网设计多年，经验丰富，设计美学与人性化相互作用的完美融合。",
      img: query.icon53.childImageSharp.gatsbyImageData,
    },
    {
      title: "安全稳定",
      describe:
        "实时监控服务器运行状态，资源占用率；程序自动进行数据库数据备份。",
      img: query.icon54.childImageSharp.gatsbyImageData,
    },
    {
      title: "完整交付",
      describe:
        "域名/空间/备案等账号，源代码，技术文档等所有的输出物全部交付给甲方。",
      img: query.icon55.childImageSharp.gatsbyImageData,
    },
    {
      title: "完善售后",
      describe:
        "7 * 12小时急速处理客户问题，包含故障修复备案，备份，安全防护，问题咨询等。",
      img: query.icon56.childImageSharp.gatsbyImageData,
    },
  ]
  return (
    <div
      css={{
        backgroundColor: "#fff",
      }}
    >
      <div className="container">
        <div className="superiority">
          <div className="title" css={{ textAlign: "center" }}>
            <h3>我们的优势</h3>
            <h4>OUR ADVANTAGES</h4>
          </div>
          <div className="box row" css={{ overflow: "hidden" }}>
            {data.map((item, d) => (
              <div key={item.title} className="col-6 col-md-4 rcol">
                <Bounce top fraction={0.2} delay={d * 100} ssrFadeout>
                  <div className="box-item">
                    <GatsbyImage
                      image={item.img}
                      alt={item.describe}
                      title={item.title}
                    />
                    <h3>{item.title}</h3>
                    <p>{item.describe}</p>
                  </div>
                </Bounce>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Superiority
