import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Zoom, Bounce } from "react-reveal"
import "./style.scss"

const Typeleft = ({}) => {
  const query = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "网站建设插图.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      img2: file(relativePath: { eq: "微信小程序插图.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      img3: file(relativePath: { eq: "移动端设计插图.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      img4: file(relativePath: { eq: "技术服务插图.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon11: file(relativePath: { eq: "i-网站建设.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon12: file(relativePath: { eq: "i-购物商城.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon13: file(relativePath: { eq: "i-行业门户.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon14: file(relativePath: { eq: "i-社区论坛.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon15: file(relativePath: { eq: "i-政府事业单位.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon21: file(relativePath: { eq: "i-微信模板.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon22: file(relativePath: { eq: "i-小程序.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon23: file(relativePath: { eq: "i-微信支付.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon31: file(relativePath: { eq: "i-手机网站.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon32: file(relativePath: { eq: "i-原生app.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon33: file(relativePath: { eq: "i-h5单页.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon41: file(relativePath: { eq: "i-技术支持.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon42: file(relativePath: { eq: "i-安全防护.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon43: file(relativePath: { eq: "i-模板定制.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon44: file(relativePath: { eq: "i-插件定制.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      icon45: file(relativePath: { eq: "i-技术方案.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)

  const data = [
    {
      title: "高端定制网站建设",
      describe:
        "企业网站，购物商城，行业门户，社区论坛，政府/事业单位等网站定制开发！",
      img: query.img1.childImageSharp.gatsbyImageData,
      icon: [
        {
          src: query.icon11.childImageSharp.gatsbyImageData,
          title: "企业网站",
        },
        {
          src: query.icon12.childImageSharp.gatsbyImageData,
          title: "购物商城",
        },
        {
          src: query.icon13.childImageSharp.gatsbyImageData,
          title: "行业门户",
        },
        {
          src: query.icon14.childImageSharp.gatsbyImageData,
          title: "社区论坛",
        },
        {
          src: query.icon15.childImageSharp.gatsbyImageData,
          title: "政府/事业单位",
        },
      ],
      type: "left",
    },
    {
      title: "微信小程序开发",
      describe:
        "基于微信/小程序模板消息，微信支付等开放组件以及接口开发类别微信场景应用！",
      img: query.img2.childImageSharp.gatsbyImageData,
      icon: [
        {
          src: query.icon21.childImageSharp.gatsbyImageData,
          title: "微信模板",
        },
        {
          src: query.icon22.childImageSharp.gatsbyImageData,
          title: "小程序",
        },
        {
          src: query.icon23.childImageSharp.gatsbyImageData,
          title: "微信支付",
        },
      ],
      type: "right",
    },
    {
      title: "移动端设计开发",
      describe: "手机网站，原生APP，API开发H5单页等移动终端产品定制开发！",
      img: query.img3.childImageSharp.gatsbyImageData,
      icon: [
        {
          src: query.icon31.childImageSharp.gatsbyImageData,
          title: "手机网站",
        },
        {
          src: query.icon32.childImageSharp.gatsbyImageData,
          title: "原生app",
        },
        {
          src: query.icon33.childImageSharp.gatsbyImageData,
          title: "h5单页",
        },
      ],
      type: "left",
    },
    {
      title: "技术服务",
      describe:
        "技术支持，安全防护，模板定制，插件定制，技术方案等技术支持服务！",
      img: query.img4.childImageSharp.gatsbyImageData,
      icon: [
        {
          src: query.icon41.childImageSharp.gatsbyImageData,
          title: "技术支持",
        },
        {
          src: query.icon42.childImageSharp.gatsbyImageData,
          title: "安全防护",
        },
        {
          src: query.icon43.childImageSharp.gatsbyImageData,
          title: "模板定制",
        },
        {
          src: query.icon44.childImageSharp.gatsbyImageData,
          title: "插件定制",
        },
        {
          src: query.icon45.childImageSharp.gatsbyImageData,
          title: "技术方案",
        },
      ],
      type: "right",
    },
  ]

  return (
    <div>
      {data.map((element, index) => {
        return (
          <div
            key={element.title}
            id={`type-left${index}`}
            className="type-left"
            css={
              index % 2 == 1
                ? {
                    backgroundColor: "#efeff3",
                  }
                : { backgroundColor: "#fff" }
            }
          >
            <div key={index} className="container">
              <div
                className="row"
                css={index % 2 == 1 ? { flexDirection: "row-reverse" } : {}}
              >
                <div className="col-12 col-md-6">
                  <div className="d-block d-md-none">
                    <h3>{element.title}</h3>
                    <p>{element.describe}</p>
                  </div>

                  <Zoom fraction={0.2} ssrFadeout>
                    <GatsbyImage
                      loading="lazy"
                      image={element.img}
                      alt={element.describe}
                      title={element.title}
                    />
                  </Zoom>
                </div>
                <div className="col-12 col-md-6">
                  <div className="d-md-block d-none">
                    <h3>{element.title}</h3>
                    <p>{element.describe}</p>
                  </div>
                  <ul
                    id={`m-ul${index}`}
                    className="list row justify-content-center"
                  >
                    {element.icon.map((item, d) => (
                      <li className="col" key={d}>
                        {index % 2 == 1 ? (
                          <Bounce
                            left
                            fraction={0.2}
                            delay={d * 100}
                            ssrFadeout
                          >
                            <div
                              css={{
                                minHeight: 32,
                              }}
                            >
                              <GatsbyImage
                                loading="lazy"
                                placeholder="blurred"
                                image={item.src}
                                alt={item.title}
                                title={item.title}
                              />
                            </div>
                            <p>{item.title}</p>
                            <div className="solid-right"></div>
                          </Bounce>
                        ) : (
                          <Bounce
                            right
                            fraction={0.2}
                            delay={d * 100}
                            ssrFadeout
                          >
                            <div
                              css={{
                                minHeight: 32,
                              }}
                            >
                              <GatsbyImage
                                loading="lazy"
                                placeholder="blurred"
                                image={item.src}
                                alt={item.title}
                                title={item.title}
                              />
                            </div>
                            <p>{item.title}</p>
                            <div className="solid-right"></div>
                          </Bounce>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Typeleft
