import React from "react"
import Layout from "../components/layouts/layout"
import Banner from "../components/banner"
import Propaganda from "../components/home/propaganda"
import Typeleft from "../components/home/customized"
import Superiority from "../components/home/superiority"

import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO
      title="首页"
      description="高端定制网站建设,微信小程序开发,移动端设计开发,技术服务"
    />
    <Banner />
    <Propaganda />
    <Typeleft />
    <Superiority />
  </Layout>
)

export default IndexPage
